import styled from '@emotion/styled'

export default styled.div`
  width: 100%;
  padding: 3rem 0;
  margin: 0 auto;

  @media screen and (min-width: 60rem)
  {
    width: 80%;
  }
`
